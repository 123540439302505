<template>
  <div>
    <v-card-text>
      <v-card outlined class="white pa-2">
        <div v-if="!this.empleados">
          <v-alert v-if="!nombrecanal" text type="info"
            >No hay data para mostrar</v-alert
          >
        </div>
        <div v-else-if="this.empleados">
          <v-row>
            <v-col cols="12" sm="6">
              <v-radio-group v-model="grupo" row v-if="gestionados.length">
                <v-radio label="Cadena de mando" value="cadena"></v-radio>
                <v-radio label="Gestionados" value="gestionado"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" sm="6">
              <div class="text-right">
                <v-btn
                  class="mr-1"
                  text
                  depressed
                  color="info"
                  @click="confirm"
                  :disabled="empleados.length == 0"
                  >Confirmar</v-btn
                >
              </div>
            </v-col>
          </v-row>

          <br />
          <br />
          <v-simple-table fixed-header height="300px" v-if="grupo == 'cadena'">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Tarjeta</th>
                  <th class="text-left">Nombre</th>
                  <th class="text-left">Grupo Comisión</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in empleados" :key="item.EmpleadosId">
                  <td class="text-uppercase">{{ item.CodigoTarjeta }}</td>
                  <td>{{ item.Nombre }}</td>
                  <td>{{ item.Puesto }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- <v-data-table
          :headers="headersEmpleado"
          :items="empleados"
          :loading="progressEmpleado"
          item-key="EmpleadosId"
        >
          <template v-slot:item.Nombre="{ item }">
            <div class="text-uppercase">{{ item.Nombre }}</div>
          </template>
        </v-data-table> -->

          <br />

          <v-simple-table
            fixed-header
            height="300px"
            v-if="grupo == 'gestionado' && gestionados.length"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Código</th>
                  <th class="text-left">Sucursal</th>
                  <th class="text-left">Asociado</th>
                  <th class="text-left">Canal</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in gestionados" :key="item.CanalSucursalId">
                  <td>{{ item.CodigoSucursal }}</td>
                  <td class="text-capitalize">{{ item.NombreSucursal }}</td>
                  <td>{{ item.CodigoAsociado }}</td>
                  <td>{{ item.Canal }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- <v-data-table
          v-if="gestionados.length"
          :headers="headersGestionado"
          :items="filteredGestionados"
          :loading="progressGestionado"
          item-key="CanalSucursalId"
        >
        </v-data-table> -->
          <br />
        </div>
        <v-alert v-if="!nombrecanal" text type="warning"
          >Es necesario seleccionar un periodo para poder navegar en
          el sistema.</v-alert
        >
      </v-card>
    </v-card-text>

    <v-fab-transition>
      <v-btn
        v-if="$vuetify.breakpoint.mobile"
        color="transparent"
        absolute
        top
        right
        fixed
        style="top: 120px; right: 0px"
        @click.native="drawerRight = !drawerRight"
      >
        <v-icon>filter_alt</v-icon>
      </v-btn>
    </v-fab-transition>

    <v-navigation-drawer v-model="drawerRight" app clipped fixed right>
      <v-card-text>
        <div class="subtitle-1 py-5">Filtros</div>
        <v-divider></v-divider>
        <br />
        <v-select
          v-model="selectedCorte"
          :items="listaCortes"
          placeholder="Seleccione un corte"
          item-text="fechas"
          item-value="CorteId"
          append-icon="keyboard_arrow_down"
          :loading="progressEmpleado"
        ></v-select>
        <br />
        <div class="mb-5 text-right">
          <v-btn
            block
            class="ma-1"
            depressed
            color="primary"
            @click="getEmpleados"
          >
            <v-icon left>search</v-icon>Buscar
            <span slot="loader">Buscando...</span>
          </v-btn>
        </div>
      </v-card-text>
    </v-navigation-drawer>
  </div>
</template>

<script>
import apiEmpleado from "@/api/empleados";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      drawerRight: true,
      cortes: [],
      empleados: [],
      gestionados: [],
      selectedCorte: {},
      selectedCanal: "",
      nombrecanal: null,
      progressEmpleado: false,
      progressGestionado: false,
      grupo: "cadena",
      // headersEmpleado: [
      //   { text: "TARJETA", value: "CodigoTarjeta" },
      //   { text: "NOMBRE", value: "Nombre" },
      //   { text: "GRUPO COMISION", value: "Puesto" },
      // ],
      // headersGestionado: [
      //   { text: "CODIGO SUCURSAL", value: "CodigoSucursal" },
      //   { text: "NOMBRE SUCURSAL", value: "NombreSucursal" },
      //   { text: "ASOCIADO", value: "CodigoAsociado" },
      //   { text: "CANAL", value: "Canal" },
      // ],
      // rowsPerPageItems: [50, 100, 200],
      // pagination: {
      //   rowsPerPage: 100,
      // },
      breadcrumb: [
        {
          text: "Configuración",
          disabled: true,
        },
      ],
      listaCanal: [],
    };
  },
  computed: {
    listaCortes() {
      return this.$store.getters.cortes;
    },
    currentUser() {
      return this.$store.getters.user;
    },
    filteredGestionados() {
      return this.gestionados.filter((i) => {
        return !this.selectedCanal || i.Canal === this.selectedCanal;
      });
    },
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    confirm() {
      this.user = this.currentUser;

      var _canales = [];
      var empleadosGestionados = [];

      if (this.gestionados.length > 0) {
        var insumo = this.gestionados.filter(
          (c) => c.TipoCanal == this.user.tipoCanal
        );

        empleadosGestionados = insumo
          .filter((c) => c.Empleados != null)
          .map((c) => c.Empleados);

        _canales = insumo
          .filter((c) => c.CanalID != undefined)
          .map((c) => c.CanalID);
      }

      _canales.push(this.user.canalid);

      var canales = [];
      canales = _canales;

      let _corte = this.$store.getters.cortes.find(
        (c) => c.CorteId === this.selectedCorte
      );

      this.user.corte = _corte;
      this.user.corteId = this.selectedCorte;
      // this.user.canalid = this.user.corte.CanalId;
      this.user.seleccion = {
        empleados: [],
        gestionados: [],
        canales: [],
      };

      this.user.seleccion.empleados =
        this.empleados.concat(empleadosGestionados);
      this.user.seleccion.gestionados = this.gestionados;
      this.user.seleccion.canales = canales;
      this.user.perfil =
        this.empleados.length > 1 ? "Gerente" : "Representante";

      this.$store.dispatch("setUser", this.user);
      this.$store.dispatch("getSegmento");
      let mensaje = {
        title: "Confirmación empleado",
        body: "Empleados seleccionados",
      };

      this.setMessage(mensaje);
      // }
      this.$router.push({ name: "home" });
    },
    getEmpleados() {
      this.progressEmpleado = true;
      apiEmpleado
        .getEmpleados(this.selectedCorte, this.$store.getters.user.empleadoid)
        .then((response) => {
          this.progressEmpleado = false;
          this.empleados = response.data;
        });
    },
    getGestionado() {
      this.progressGestionado = true;

      const empleadoId = this.currentUser.empleadoid === null?0:this.currentUser.empleadoid;

      apiEmpleado
        .getGestionado(empleadoId)
        .then((response) => {
          this.progressGestionado = false;
          this.gestionados = response.data;

          this.listaCanal = [...new Set(response.data.map((c) => c.Canal))];
        });
    },
  },
  // watch: {
  //   selectedCorte: function () {
  //     this.getEmpleados();
  //   },
  // },
  mounted() {
    this.empleados = null;
    // this.$store.dispatch("setBreadcrumbs", this.breadcrumb);
    this.getGestionado();
    if (this.currentUser.corteId) {
      this.selectedCorte = this.currentUser.corteId;
      this.selectedGestionado = this.currentUser.seleccion.gestionados;
    } else {
      if (this.$store.getters.cortes.length > 0) {
        this.selectedCorte =
          this.$store.getters.cortes[
            this.$store.getters.cortes.length - 1
          ].CorteId;
      }
    }
  },
};
</script>